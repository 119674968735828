import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import moment from "moment";
// eslint-disable-next-line import/no-unresolved
import { useLocation } from "@reach/router";
// import discussionImg from "../images/discussion.png"

export const ELECTION_DATE = new Date("25 Feb 2023 00:00:00 GMT+1");

function Header() {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const location = useLocation();
  const getActiveNavClass = (path) => {
    return location.pathname === path
      ? "font-bold md:border-b-4 md:border-black"
      : "";
  };

  const calculateTTG = () => {
    const electionDate = moment(ELECTION_DATE.toISOString());
    return moment.duration(electionDate.diff(moment()));
  };

  const [ttgObj, setTtgObj] = useState(calculateTTG());
  useEffect(() => {
    setInterval(() => {
      if (ttgObj) setTtgObj(calculateTTG());
    }, 1000);
  }, []);

  const getTtgSentence = () => {
    const months = ttgObj.months(),
      weeks = ttgObj.weeks(),
      hours = ttgObj.hours(),
      mins = ttgObj.minutes(),
      secs = ttgObj.seconds();
    return (
      <>
        {months}month{months !== 1 && "s"}, {weeks}week{weeks !== 1 && "s"}, {hours}hour
        {hours !== 1 && "s"}, {mins}
        min{mins !== 1 && "s"}, {secs}second{secs !== 1 && "s"}
      </>
    );
  };

  const [mobileNavActive, setMobileNavActive] = useState(false);
  const toggleMobileNav = () => {
    setMobileNavActive(!mobileNavActive);
  };

  const navLinks = [
    {
      title: "2023 Elections",
      link: "/",
    },
    {
      title: "Candidates",
      link: "/candidates",
    },
    {
      title: "PVC Collection",
      link: "/pvc",
    },
  ];

  return (
    <header className="bg-light-gray pt-4 pb-2 md:pt-6 md:pb-0">
      <div className="container mx-auto px-3 md:px-0">
        <section className="flex flex-wrap md:pt-12 md:pb-16 pt-4">
          <div className="flex items-center flex-shrink-0 font-bold pr-5 md:pb-4 pb-4">
            <Link to="/">🇳🇬 {site.siteMetadata.title}</Link>
          </div>

          <div className="w-3/4 flex sm:border-l-4 sm:border-black sm:pl-5 h-60 md:h-auto">
            <h2 className="text-gray-700 text-3xl">
              The 2023 Nigerian elections will commence <br></br>
              <span className="font-bold text-black">
                {getTtgSentence()}
              </span>{" "}
              from now!
            </h2>
          </div>

          <div className="text-right flex-1">
            {/* <input
              className="appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-light-gray"
              type="text"
              placeholder="search"
            /> */}
          </div>
        </section>

        {/* <img className="absolute top-20 right-1/3" src={discussionImg}></img> */}

        <div className="md:hidden flex items-center mt-4">
          <button
            className="outline-none mobile-menu-button"
            onClick={toggleMobileNav}
          >
            <svg
              className="w-6 h-6 text-gray-500"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>

        <div
          className={"md:hidden pt-2 pl-1 " + (mobileNavActive ? "" : "hidden")}
        >
          <ul>
            {navLinks.map((nav, i) => (
              <li key={i} className="active">
                <Link
                  to={nav.link}
                  className={
                    "block text-sm pb-3 md:pb-2 lg:inline-block lg:mt-0 hover:text-gray-600 " +
                    getActiveNavClass(nav.link)
                  }
                >
                  {nav.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <nav className="hidden md:flex items-center justify-between flex-wrap md:pt-6 pt-8">
          {/* <div className="block lg:hidden">
            <button className="flex items-center px-3 py-2 border-2 rounded text-teal-200 border-gray-500 hover:text-white hover:border-gray-600">
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div> */}

          <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
            <div className="text-sm lg:flex-grow text-right md:text-left">
              {navLinks.map((nav, i) => (
                <Link
                  key={i}
                  to={nav.link}
                  className={
                    "block pb-3 md:pb-2 lg:inline-block lg:mt-0 hover:text-gray-600 mr-10 " +
                    getActiveNavClass(nav.link)
                  }
                >
                  {nav.title}
                </Link>
              ))}
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
